import { getAuth, connectAuthEmulator } from 'firebase/auth';
import app from './firebase';

const auth = getAuth(app);

if (process.env.REACT_APP_RUN_EMULATORS === 'true') {
    connectAuthEmulator(auth, 'http://localhost:9099');
}

export default auth;
