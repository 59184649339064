import React, { useEffect, useState } from 'react';
import functions from '../../firebase/functions';
import auth from '../../firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { ActivityCard } from '../../components/activity-card';
import { Link } from 'react-router-dom';
import { useAthlete } from '../../context/athlete';
import { Navigation } from '../../components/Navigation';

function Dashboard() {
    const currentUser = auth.currentUser;
    const [activities, setActivities] = useState([]);
    const { athlete }: any = useAthlete();

    useEffect(() => {
        currentUser?.getIdToken().then((res) => {
            const getActivities = httpsCallable(
                functions,
                'activities-getActivities'
            );
            getActivities()
                .then((result: any) => {
                    setActivities(result.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, [athlete]);

    return (
        <>
            <Navigation />
            {athlete && (
                <div className="container">
                    {!athlete?.scope?.includes('activity:write') ? (
                        <div className="alert alert-danger" role="alert">
                            We need access to the upload activities permission
                            to add the description to your activities. Please
                            logout and in to update your permissions.
                        </div>
                    ) : !athlete?.scope?.includes('profile:read_all') ? (
                        <div className="alert alert-warning" role="alert">
                            We use your complete Strava profile information to
                            know whether to use metric or imperial mesurements.
                            Please logout and in to update your permissions.
                        </div>
                    ) : (
                        !athlete?.scope?.includes('activity:read_all') && (
                            <div className="alert alert-warning" role="alert">
                                Without access to your private activities these
                                cannot be included in your progress statistics.
                                Please logout and in to update your permissions.
                            </div>
                        )
                    )}

                    <div className="alert alert-success" role="alert">
                        <p>Thank you for joining.</p>
                        <p>
                            You can customise the description added to your
                            activities on the{' '}
                            <Link to="/settings">settings page</Link>.
                        </p>
                        <p>You can choose from:</p>
                        <ul>
                            <li>
                                Distance - Rolling 7 day, Weekly and Monthly
                            </li>
                            <li>elevation - Weekly and Monthly</li>
                            <li>Duration - Weekly and Monthly</li>
                        </ul>

                        <p>
                            Do you have feedback on my-progress.app? We'd love
                            to hear it at{' '}
                            <a href="mailto:info@my-progress.app">
                                info@my-progress.app
                            </a>
                        </p>
                    </div>
                    <div className="row dashboard">
                        <>
                            <h4>Recent Activites</h4>

                            {activities?.length > 0 ? (
                                activities.map((activity, i) => {
                                    return (
                                        <ActivityCard
                                            key={i}
                                            activity={activity}
                                        />
                                    );
                                })
                            ) : (
                                <div className="lds-container">
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            )}
        </>
    );
}

export default Dashboard;
