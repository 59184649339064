import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import app from './firebase';

const functions = getFunctions(app);

if (process.env.REACT_APP_RUN_EMULATORS === 'true') {
    connectFunctionsEmulator(functions, 'localhost', 4001);
}

export default functions;
