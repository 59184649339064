import React from 'react';

const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_DOMAIN } = process.env;

const myProgressLogo = require('./myprogress.png');
const poweredByStrava = require('./api_logo_pwrdBy_strava_horiz_white.png');
const stravaAuth = require('./btn_strava_connectwith_orange.png');
const stravaMockUp = require('./StravaAppmockup.png');

const loginUrl = `https://www.strava.com/oauth/authorize?response_type=code&redirect_uri=${REACT_APP_CLIENT_DOMAIN}authCallback&scope=profile:read_all,activity:write,activity:read_all&client_id=${REACT_APP_CLIENT_ID}`;

function Landing() {
    return (
        <div className="d-flex h-100 mx-auto flex-column">
            <div className="intro-panel">
                <div className="content">
                    <header className="mb-auto">
                        <div className="inner">
                            <img
                                src={myProgressLogo}
                                alt="My Progress"
                                className="logo"
                            />
                            <nav className="nav nav-masthead">
                                <img
                                    className="pwd-logo"
                                    alt="Powered by Strava"
                                    src={poweredByStrava}
                                />
                            </nav>
                        </div>
                    </header>
                    <div className="intro-row">
                        <main role="main" className="cover main col-md-7">
                            <h1 className="cover-heading">
                                A little extra motivation...
                            </h1>
                            <p className="lead">
                                Get the extra motivation you need by adding
                                monthly, weekly and rolling activity totals to
                                your Strava activity Descriptions.
                            </p>
                            <p className="lead">
                                <a href={loginUrl} className="btn connect-btn">
                                    <img
                                        alt="Connect with Strava"
                                        src={stravaAuth}
                                    />
                                </a>
                            </p>
                        </main>
                        <div className="col-md-5 image-container">
                            <img
                                src={stravaMockUp}
                                className="mockup"
                                alt="Description example"
                                width="340px"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row image-secondary">
                <img
                    src={stravaMockUp}
                    className="mockup"
                    alt="Description example"
                    width="340px"
                />
            </div>
            <div className="row">
                <main
                    role="main"
                    className="main col-sm-10 offset-sm-1 col-lg-8 offset-lg-2"
                >
                    <h2 className="h2">How does it work?</h2>
                    <div className="row padding-remove">
                        <div className="col-md-6">
                            <p>
                                After each activity my-progress.app calculates
                                the time, distance or elevation you have covered
                                in the previous 7 days, week or month and adds
                                it to your activity description.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Currently you can add your weekly and monthly
                                distance, elevation gain and time to your
                                activities as well as a 7 day rolling distance.
                            </p>
                        </div>
                    </div>
                </main>
            </div>

            <div className="row padding-add">
                <main
                    role="main"
                    className="main col-sm-10 offset-sm-1 col-lg-8 offset-lg-2"
                >
                    <h2 className="h2">Why do I need it?</h2>
                    <div className="row padding-remove">
                        <div className="col-md-6">
                            <p>
                                We built MyProgress because we wanted to be able
                                to easily see the level of fatigue when
                                reviewing past activities.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                The bonus which we didn't expect was the extra
                                motivation it provides when you get easily see
                                how much you've achieved each week or month.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
            <div className="row secondary">
                <main className="main col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                    <div className="row secondary padding-remove">
                        <div className="col-md-4">
                            <p>
                                <b>What data do you store?</b>
                                <br />
                                We only store enough information to allow us to
                                calculate your load and to allow you to identify
                                your runs on your dashboard.
                            </p>
                            <p>
                                Specifically we store the type of activity e.g.
                                run or ride, the distance, your moving time, the
                                start time and the elevation gain.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <p>
                                <b>Is there a cost?</b>
                                <br />
                                It's free to add weekly and 7 day distance
                                statistics to your activites. If you would like
                                to add more statistics and duration options to
                                your activites we charge a small cost of $1 USD
                                per month.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <p>
                                <b>Why do you charge for some features?</b>
                                <br />
                                We built my-progress.app because it was
                                something we wanted for ourselves, however there
                                are costs for hosting the app and the premium
                                feature cost helps to cover these expenses.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Landing;
