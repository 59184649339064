import React from 'react';
import moment from 'moment';
import functions from '../../firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useAthlete } from '../../context/athlete';

export const ManageSubscription = function ManageSubscription(props: any): any {
    const { athlete }: any = useAthlete();

    return (
        <div className="card subscription-panel">
            <div className="card-body">
                <h3>Current Plan</h3>
                <div>
                    <h5>Monthly Subscription</h5>
                    $1 USD per month
                    <br />
                    <span className="renewal-date">
                        Your plan renews on{' '}
                        {moment
                            .unix(athlete.subscriptionPaidTo)
                            .format('MMMM DD, YYYY')}
                    </span>
                </div>

                <div className="row padding-minimal">
                    <button
                        className="btn btn-settings"
                        onClick={async () => {
                            const createManageSession = httpsCallable(
                                functions,
                                'payments-createManageSession'
                            );
                            await createManageSession(athlete)
                                .then((res: any) => {
                                    window.open(res.data, '_self');
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }}
                    >
                        Manage Subscription
                    </button>
                </div>
            </div>
        </div>
    );
};
