import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyDMjpASXqo0m9qlg9vIUcgL5lcapdvx278',
    authDomain: 'my-progressapp.firebaseapp.com',
    projectId: 'my-progressapp',
    storageBucket: 'my-progressapp.appspot.com',
    messagingSenderId: '602370555846',
    appId: '1:602370555846:web:782cfc7bbe11dee0a33cc7',
};

const app = initializeApp(firebaseConfig);

export default app;
