import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import auth from '../../firebase/auth';
import { useAthlete } from '../../context/athlete';

const myProgressLogo = require('./myprogress.png');
const poweredByStrava = require('./api_logo_pwrdBy_strava_horiz_white.png');

export const Navigation = function Navigation(props: any, children: any): any {
    const navigate = useNavigate();
    const { athlete, setAthlete } = useAthlete();

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-progress fixed-top">
                <Link className="navbar-brand" to="/">
                    <img
                        src={myProgressLogo}
                        alt="My Progress"
                        className="logo"
                    />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    id="toggler"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item"></li>
                    </ul>
                    <Link to="/dashboard" className="btn nav-btn">
                        Home
                    </Link>
                    <Link to="/settings" className="btn nav-btn">
                        Settings
                    </Link>
                    <button
                        className="btn nav-btn"
                        onClick={() => {
                            signOut(auth).then(() => {
                                setAthlete({});
                                navigate('/', { replace: true });
                            });
                        }}
                        id="sign out"
                    >
                        Logout
                    </button>
                    <img
                        className="pwd-logo"
                        alt="powered by Strava"
                        src={poweredByStrava}
                    />
                </div>
            </nav>
        </div>
    );
};
