import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import functions from '../../firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useAthlete } from '../../context/athlete';
import moment from 'moment';
import { Navigation } from '../../components/Navigation';
import { Payment } from '../../components/payment';
import { ManageSubscription } from '../../components/manage-subscription';

function Settings() {
    const { athlete, setAthlete } = useAthlete();
    const [isProUser, setIsProUser] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [settings, setSettings]: any = useState(athlete);
    const [success, setSuccess]: any = useState(false);
    const [sessionId, setSessionId]: any = useState('');
    const [message, setMessage]: any = useState('');
    const [searchParams] = useSearchParams({});

    useEffect(() => {
        setSettings(athlete);
        if (searchParams.get('success')) {
            setSuccess(true);
            setSessionId(searchParams.get('session_id'));
        }

        if (sessionId) {
            const retrievedSession = httpsCallable(
                functions,
                'payments-getCheckoutSession'
            );
            retrievedSession({ sessionID: sessionId })
                .then((res: any) => {
                    if (
                        res.data.subscription &&
                        res.data.payment_status === 'paid'
                    ) {
                        setIsProUser(1);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (searchParams.get('canceled')) {
            setSuccess(false);
            setMessage('Subscription checkout canceled');
        }

        // if (success && sessionId !== '') {
        //     setIsProUser(1);
        // } else {
        setIsProUser(
            settings?.isSponsoredUser ||
                (settings?.subscriptionPaidTo !== undefined &&
                    moment().isBefore(
                        moment.unix(settings.subscriptionPaidTo).add(24, 'h')
                    ))
                ? 1
                : 0
        );
        // }
    }, [athlete, sessionId]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSaving(true);
        setAthlete({
            ...athlete,
            ...settings,
        });
        const updateAthlete = httpsCallable(functions, 'athlete-updateAthlete');
        await updateAthlete(settings).catch((error) => {
            console.log(error);
        });
        setIsSaving(false);
        window.scrollTo(0, 0);
    };

    const updateActivitiesFromStava = async (e: any) => {
        e.preventDefault();
        setIsUpdating(true);
        const getActivities = httpsCallable(
            functions,
            'activities-getMoreActivities'
        );
        await getActivities().catch((error) => {
            console.log(error);
        });
        setIsUpdating(false);
    };

    const handleChange = (event: any) => {
        if (event.target.value === 'meters' || event.target.value === 'feet') {
            setSettings({
                ...settings,
                [event.target.name]: String(event.target.value),
            });
        } else {
            setSettings({
                ...settings,
                [event.target.name]: Number(event.target.value),
            });
        }
    };

    return (
        <>
            <Navigation />
            <div className="col-sm-10 offset-sm-1">
                {!success && message === '' ? (
                    settings.subscriptionPaidTo > moment().unix() ? (
                        <ManageSubscription />
                    ) : (
                        <Payment />
                    )
                ) : success && sessionId !== '' ? (
                    <div className="checkout-notification">
                        <div className="alert  alert-success" role="alert">
                            <p>Thank you for joining.</p>
                        </div>
                    </div>
                ) : (
                    <div className="checkout-notification">
                        <div className="alert alert-danger" role="alert">
                            <p>{message}</p>
                        </div>
                    </div>
                )}

                <div className="row dashboard padding-minimal">
                    <h4>Settings</h4>
                </div>

                {settings.updateActivities ? (
                    <form className="form-horizontal">
                        <div className="card">
                            <div className="card-body">
                                <div className="row padding-minimal d-flex">
                                    <h3>User Preferences</h3>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Update Activities:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="updateActivitiesYes"
                                            name="updateActivities"
                                            value={1}
                                            checked={
                                                settings.updateActivities === 1
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Update
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="updateActivitiesNo"
                                            name="updateActivities"
                                            value={0}
                                            checked={
                                                settings.updateActivities === 0
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Pause
                                        </span>
                                        <small className="form-text text-muted">
                                            Update your activites with your
                                            progress.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Measurement Preference:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="measurementPreferenceKM"
                                            name="measurementPreference"
                                            value="meters"
                                            checked={
                                                settings.measurementPreference ===
                                                'meters'
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            KM
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="measurementPreferenceMiles"
                                            name="measurementPreference"
                                            value="feet"
                                            checked={
                                                settings.measurementPreference ===
                                                'feet'
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Miles
                                        </span>
                                        <small className="form-text text-muted">
                                            Choose your distance unit
                                            preference.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Runs:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeRunsYes"
                                            name="includeRuns"
                                            value={1}
                                            checked={settings.includeRuns === 1}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeRunsNo"
                                            name="includeRuns"
                                            value={0}
                                            checked={settings.includeRuns === 0}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Add descriptions to runs.
                                        </small>
                                    </div>
                                </div>{' '}
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Rides:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeRidesYes"
                                            name="includeRides"
                                            value={1}
                                            checked={
                                                settings.includeRides === 1
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeRidesNo"
                                            name="includeRides"
                                            value={0}
                                            checked={
                                                settings.includeRides === 0
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Add descriptions to Rides.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Swims:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeSwimsYes"
                                            name="includeSwims"
                                            value={1}
                                            checked={
                                                settings.includeSwims === 1
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeSwimsNo"
                                            name="includeSwims"
                                            value={0}
                                            checked={
                                                settings.includeSwims === 0
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Add descriptions to swims.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Others:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeOthersYes"
                                            name="includeOthers"
                                            value={1}
                                            checked={
                                                settings.includeOthers === 1
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeOthersNo"
                                            name="includeOthers"
                                            value={0}
                                            checked={
                                                settings.includeOthers === 0
                                            }
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Add descriptions to other
                                            activities.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <h3>Statistic Preferences</h3>
                                </div>
                                {!isProUser && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        <p>
                                            The customisations below are
                                            available to subscribers only. The
                                            small subscription fee helps cover
                                            the cost of hosting and maintenance.
                                        </p>
                                    </div>
                                )}
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Rolling Seven Day Distance:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeSevenDayTotalYes"
                                            name="includeSevenDayTotal"
                                            value={1}
                                            checked={
                                                settings.includeSevenDayTotal ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeSevenDayTotalNo"
                                            name="includeSevenDayTotal"
                                            value={0}
                                            checked={
                                                settings.includeSevenDayTotal ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your rolling seven day mileage will
                                            be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Weekly Distance:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyTotalYes"
                                            name="includeWeeklyTotal"
                                            value={1}
                                            checked={
                                                settings.includeWeeklyTotal ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyTotalNo"
                                            name="includeWeeklyTotal"
                                            value={0}
                                            checked={
                                                settings.includeWeeklyTotal ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative weekly mileage will
                                            be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Monthly Distance:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyTotalYes"
                                            name="includeMonthlyTotal"
                                            value={1}
                                            checked={
                                                settings.includeMonthlyTotal ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyTotalNo"
                                            name="includeMonthlyTotal"
                                            value={0}
                                            checked={
                                                settings.includeMonthlyTotal ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative monthly mileage will
                                            be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Weekly Elevation:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyElevationYes"
                                            name="includeWeeklyElevation"
                                            value={1}
                                            checked={
                                                settings.includeWeeklyElevation ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyElevationNo"
                                            name="includeWeeklyElevation"
                                            value={0}
                                            checked={
                                                settings.includeWeeklyElevation ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative weekly elevation
                                            will be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Monthly Elevation:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyElevationYes"
                                            name="includeMonthlyElevation"
                                            value={1}
                                            checked={
                                                settings.includeMonthlyElevation ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyElevationNo"
                                            name="includeMonthlyElevation"
                                            value={0}
                                            checked={
                                                settings.includeMonthlyElevation ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative monthly elevation
                                            will be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Weekly Duration:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyDurationYes"
                                            name="includeWeeklyDuration"
                                            value={1}
                                            checked={
                                                settings.includeWeeklyDuration ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeWeeklyDurationNo"
                                            name="includeWeeklyDuration"
                                            value={0}
                                            checked={
                                                settings.includeWeeklyDuration ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative weekly duration will
                                            be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal d-flex">
                                    <div className="col-lg-3">
                                        <label className="form-label">
                                            Include Monthly Duration:{' '}
                                        </label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyDurationYes"
                                            name="includeMonthlyDuration"
                                            value={1}
                                            checked={
                                                settings.includeMonthlyDuration ===
                                                1
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            Yes
                                        </span>

                                        <input
                                            className="form-input"
                                            type="radio"
                                            id="includeMonthlyDurationNo"
                                            name="includeMonthlyDuration"
                                            value={0}
                                            checked={
                                                settings.includeMonthlyDuration ===
                                                0
                                            }
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isProUser}
                                        />
                                        <span className="form-radio-label">
                                            No
                                        </span>
                                        <small className="form-text text-muted">
                                            Your cumulative monthly duration
                                            will be added to the activity
                                            description.
                                        </small>
                                    </div>
                                </div>
                                <div className="row padding-minimal">
                                    <button
                                        id="save"
                                        className="btn btn-settings"
                                        onClick={handleSubmit}
                                        type="submit"
                                        disabled={isSaving}
                                    >
                                        {isSaving ? (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <>Save</>
                                        )}
                                    </button>
                                </div>
                                <div className="row padding-minimal settings-intro">
                                    <h3>Update Activities from Strava</h3>
                                    <p>
                                        Your activites will sync from Strava
                                        automatically but if any are missing you
                                        can refresh using the button below.
                                    </p>

                                    <button
                                        className="btn btn-settings"
                                        disabled={isUpdating}
                                        onClick={(e) =>
                                            updateActivitiesFromStava(e)
                                        }
                                        id="update"
                                    >
                                        {isUpdating ? (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <>Update</>
                                        )}{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="lds-container">
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Settings;
