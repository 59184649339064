import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    signInWithCustomToken,
    setPersistence,
    browserLocalPersistence,
} from 'firebase/auth';
import auth from '../firebase/auth';
import functions from '../firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useAthlete } from '../context/athlete';

function AuthCallback() {
    const navigate = useNavigate();
    const { setAthlete } = useAthlete();
    const [searchParams] = useSearchParams({});
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');

    useEffect(() => {
        const authCallback = httpsCallable(functions, 'authCallback');
        authCallback({
            code,
            scope,
        }).then((res: any) => {
            setPersistence(auth, browserLocalPersistence).then(() => {
                signInWithCustomToken(auth, res.data)
                    .then(() => {
                        const initialiseAthlete = httpsCallable(
                            functions,
                            'athlete-initialiseAthlete'
                        );

                        initialiseAthlete()
                            .then((athleteResult: any) => {
                                setAthlete(athleteResult.data);
                                navigate('/dashboard', { replace: true });
                            })
                            .catch((error) => {
                                console.log(error.message);
                                navigate('/', { replace: true });
                            });
                    })
                    .catch((error) => {
                        console.log(error.message);
                        navigate('/', { replace: true });
                    });
            });
        });
    }, []);

    return (
        <div className="authCallback-container">
            <div className="lds-container">
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div>Getting user details...</div>
            </div>
        </div>
    );
}

export default AuthCallback;
