import React, { useState } from 'react';
import App from '../App';
import AuthCallback from '../strava/authCallback';
import Landing from '../pages/landing-page/landing-page';
import Dashboard from '../pages/dashboard/dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AthleteProvider } from '../context/athlete';
import Settings from '../pages/settings/settings';
import functions from '../firebase/functions';
import { httpsCallable } from 'firebase/functions';
import auth from '../firebase/auth';

function AppRouter() {
    const [athlete, setAthlete] = useState({});
    const currentUser = auth.currentUser;

    auth.onAuthStateChanged((res) => {
        //@ts-ignore
        if (!athlete.accessToken && currentUser) {
            const initialiseAthlete = httpsCallable(
                functions,
                'athlete-initialiseAthlete'
            );

            initialiseAthlete()
                .then((athleteResult: any) => {
                    setAthlete(athleteResult.data);
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    });

    return (
        <AthleteProvider value={{ athlete, setAthlete }}>
            <Router>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/authCallback" element={<AuthCallback />} />
                    <Route path="/settings" element={<Settings />} />
                </Routes>
            </Router>
        </AthleteProvider>
    );
}

export default AppRouter;
