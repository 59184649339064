import React from 'react';
import moment from 'moment';
import { httpsCallable } from 'firebase/functions';
import { useAthlete } from '../../context/athlete';
import functions from '../../firebase/functions';

export const ActivityCard = function ActivityCard(props: any): any {
    const { activity } = props;
    const { athlete }: any = useAthlete();
    const measurementPreference = athlete?.measurementPreference;
    const distanceString =
        measurementPreference === 'feet'
            ? Math.round(activity.distance / 1.609 / 100) / 10 + 'mi'
            : Math.round(activity.distance / 100) / 10 + ' km';

    return (
        <div className="col-lg-12">
            <div className="card" key={activity.id}>
                <div className="card-header">
                    <i
                        className={
                            'fas' +
                            (activity.type === 'Run' ? ' fa-running' : '') +
                            (activity.type === 'Ride' ? ' fa-biking' : '')
                        }
                    ></i>
                    {distanceString} {activity.type}:{' '}
                    {moment
                        .unix(activity.start_date)
                        .utc()
                        .format('dddd Do MMMM, h:mm a')}
                    <button
                        className="activity-button activity-button-primary"
                        onClick={() => {
                            const updateActivity = httpsCallable(
                                functions,
                                'activities-updateActivity'
                            );

                            updateActivity({ activity })
                                .then((athleteResult: any) => {
                                    console.log(
                                        'Update Activity',
                                        athleteResult
                                    );
                                })
                                .catch((error) => {
                                    console.log(error.message);
                                });
                        }}
                    >
                        Update Strava
                    </button>
                    <a
                        className="activity-button"
                        href={`https://www.strava.com/activities/${activity.id}`}
                        target="_blank"
                    >
                        View on Strava
                    </a>
                </div>
                <div className="card-body">
                    <p className="card-text">{activity.description}</p>
                </div>
            </div>
        </div>
    );
};
