import React, { useState, createContext, useContext, useMemo } from 'react';

const AthleteContext = createContext({
    athlete: {},
    setAthlete: (value: any) => {
        value;
    },
});

function useAthlete() {
    const context = useContext(AthleteContext);
    if (!context) {
        throw new Error('useAthlete must be used within a AthleteProvider');
    }
    return context;
}

function AthleteProvider(props: any) {
    const [athlete, setAthlete] = useState();
    const value = useMemo(() => [athlete, setAthlete], [athlete]);
    return <AthleteContext.Provider value={value} {...props} />;
}

export { AthleteProvider, useAthlete };
