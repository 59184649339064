import React from 'react';
import { useNavigate } from 'react-router-dom';
import functions from '../../firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useAthlete } from '../../context/athlete';

export const Payment = function Payment(props: any): any {
    const navigate = useNavigate();
    const { athlete }: any = useAthlete();

    return (
        <div className="card subscription-panel">
            <div className="card-body">
                <h3>Subscription</h3>
                <p>
                    Subscribers gain access to all preferences options for $1
                    USD per month with the option to cancel anytime.
                </p>
                <p>
                    Our subscriptions are processed using Stripe Payments to
                    provide industry leading security and functionality.
                </p>

                <button
                    onClick={async () => {
                        const createCheckoutSession = httpsCallable(
                            functions,
                            'payments-createCheckoutSession'
                        );
                        await createCheckoutSession(athlete)
                            .then((res: any) => {
                                window.open(res.data, '_self');
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }}
                    disabled={!athlete.firstName}
                    className="btn btn-settings"
                >
                    Subscribe
                </button>
            </div>
        </div>
    );
};
